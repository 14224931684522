import React from "react";
import Sidebar from "react-sidebar";
import Logo from "./images/humanoid_title.png";

function App() {
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const renderSidebarContent = () => {
    return (
      <div className="flex flex-col items-start">
        <button onClick={() => setOpenSidebar(false)} className="p-4">
          <i className="fa-solid fa-xmark text-lg"></i>
        </button>
        <a
          target="_blank"
          href="https://www.tiktok.com/@wearehumanoid"
          className="p-4"
          rel="noreferrer"
        >
          <i className="fa-brands fa-tiktok text-2xl"></i>
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/wearehumanoid/"
          className="p-4"
          rel="noreferrer"
        >
          <i className="fa-brands fa-instagram text-2xl"></i>
        </a>
      </div>
    );
  };
  return (
    <div>
      <Sidebar
        sidebarClassName="w-[300px]"
        sidebar={renderSidebarContent()}
        open={openSidebar}
        onSetOpen={() => setOpenSidebar(!openSidebar)}
        styles={{ sidebar: { background: "white" } }}
      >
        <header className="h-[40px] p-2 text-center font-bold bg-white">
          The bold new vision for beauty.
        </header>
        <main className="h-[calc(100vh-40px)] flex justify-center items-center relative bg-hero-image bg-cover">
          <button
            className="absolute top-6 left-6"
            onClick={() => setOpenSidebar(true)}
          >
            <i className="text-white fa-solid fa-lg fa-bars text-xl"></i>
          </button>
            <div>
          <div className="flex flex-col">
          <img className="flex justify-center" src={Logo} alt="logo" />
          <a className="text-center text-3xl p-10 text-bold text-white text-underline" href="https://forms.gle/zwcfRYotvVvM947w9">join the movement {"->"}</a>
          </div>
          </div>
        </main>
      </Sidebar>
    </div>
  );
}

export default App;
